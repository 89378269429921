import {IconHome2} from '@tabler/icons-react';
import {IconInfoCircleFilled} from '@tabler/icons-react';
import {IconBrandAzure} from '@tabler/icons-react';
import {IconDeviceImacCode} from '@tabler/icons-react';

export const MenuData = [
    {
        title: "Home",
        URL:'/',
        cName:'nav-links',
        icon: <IconHome2/>
    },
    {
        title: "Nosotros",
        URL:'#nosotros',
        cName:'nav-links',
        icon: <IconInfoCircleFilled/>
    },
    {
        title: "Servicios",
        URL:'#servicios',
        cName:'nav-links',
        icon: <IconBrandAzure/>
    },
    {
        title: "Proyectos",
        URL:'#proyectos',
        cName:'nav-links',
        icon: <IconDeviceImacCode/>
    },
    {
        title: "Contáctanos",
        URL:'/contacto',
        cName:'nav-links-mobile',
    }

]
