import NavBar from '../Components/JS/NavBar/NavBar.js';
import { Banner } from '../Components/JS/Banner.js';
import { Nosotros } from '../Components/JS/Nosotros.js';
import { Services } from '../Components/JS/Servicios/Services.js';
import { Projects } from '../Components/JS/Proyectos/Projects.js';
import { Newsletter } from '../Components/JS/Newsletter.js';
import { Footer } from '../Components/JS/Footer.js';

export const Home = () => {
  return (
    <div className='Home'>
      <NavBar />
      <Banner />
      <Nosotros />
      <Services />
      <Projects />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Home
