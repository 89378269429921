import '../Style/Banner.css';
import { Col, Container, Row } from 'react-bootstrap'
import {IconCode} from '@tabler/icons-react';
import {IconArrowBigRightLinesFilled} from '@tabler/icons-react';
import {IconBrandFacebook} from '@tabler/icons-react';
import {IconBrandInstagram} from '@tabler/icons-react';
import {IconBrandWhatsapp} from '@tabler/icons-react';
import HeaderImg from '../../Assets/headerImg.svg';

export const Banner = () => {
    return (
        <section className='banner' id='home'>
            <Container>
                <Row className='align-items-center'>
                    <Col className='col1'>
                        <span className='tagline'>
                        <IconCode className='iconCode'/>Work Outsourcing Software Solutions
                        </span>
                        <h1>Impulsa tu Negocio <br/> con Software a la medida</h1>
                        <p>Con nuestro equipo capacitado y los serivicios disponibles,
                            tienes las mejores opciones para comenzar en el mundo digital
                            y hacer crecer tu negocio.
                        </p>
                        <a href='/contacto' className='btn-consulta'>Consulta<IconArrowBigRightLinesFilled className='icon'/></a>
                    </Col>
                    <Col>
                        <img src={HeaderImg} alt='Headder Img' />
                    </Col>
                    <Col className='social'>
                        <a href='d'><IconBrandFacebook className='img'/></a>
                        <a href='d'><IconBrandInstagram className='img'/></a>
                        <a href='d'><IconBrandWhatsapp className='img'/></a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
