import { db } from '../../Firebase/Firebase';
import { useState } from 'react';
import '../Style/Newsletter.css'
import { FcAbout, FcAnswers } from 'react-icons/fc';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import Toast from './Toast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Newsletter = ()  => {
    const [input, setInput] = useState('');

    const submit = () => {
        toast.success('Te has Suscrito! ✅', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    };
    
    const inputHandler = (e) => {
        setInput(e.target.value);
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if (input){
            await addDoc(collection(db, 'emails'),{
                email: input,
                date: serverTimestamp(),
            })
            setInput('');
        }
    };

    return (
        <div className='news-container'>
            <h1>Suscribte para estar informado</h1>
            <Toast />
            <div className='news'>
                <FcAbout  className='about-icon'/>
                <FcAnswers className='answer-icon'/>
                <form className='news-form' onSubmit={submitHandler}>
                    <h2>Correo Electrónico</h2>
                    <input type='email' onChange={inputHandler} placeholder='Email Address' value={input}/>
                    <button type='submit' onClick={submit}>
                        Enviar
                    </button>
                </form>
            </div>
        </div>
    )
}
