import '../../Style/Navbar.css';
import { MenuData } from './MenuData';
import Logo from '../../../Assets/Logo.png';

const { Component } = require("react");

class NavBar extends Component{
    state = {clicked: false};
    handleClick = () => {
        this.setState({clicked: !this.state.clicked});
    };

    render(){
        return(
            <nav className="NavbarItems">
                <img src={Logo} alt="Logo" className='Logo' />
                <div className='menu-icons' onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fa-brands fa-mixer' : 'fa-solid fa-bars-staggered' } ></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuData.map((item, index) => {
                        return(
                            <li key={index}>
                                <a href={item.URL} className={item.cName}>
                                    <i>{item.icon}</i>
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        );
    }
}

export default NavBar;