import React, { useEffect, useState } from 'react'
import ContactImg from '../../Assets/ContactImg.svg';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { validateEmail, validateFullName, validateMessage } from './Validacion/Validation';
import { InlineError } from './Validacion/InlineError';
import { SendEmail } from '../../API/api';
import { toast } from 'react-toastify';
import { BsArrowLeftCircle } from 'react-icons/bs';
import Toast from './Toast';
import 'react-toastify/dist/ReactToastify.css';
import '../Style/Contact.css';

export const Contacto = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [fullNameError, setFullNameError] = useState()
    const [emailError, setEmailError] = useState()
    // const [phoneError, setPhoneError] = useState()
    const [messageError, setMessageError] = useState()
    const [send, setSend] = useState();

    useEffect(() => {
    //*VALIDATION
    validateFullName({fullName, setFullNameError});
    validateEmail({email, setEmailError});
    // validatePhone({phone, setPhoneError});
    validateMessage({message, setMessageError});

    if(send){
        toast.success(send.msg);
        setFullName('')
        setEmail('')
        setPhone('')
        setCompany('')
        setMessage('')
        setSend()
    }

    }, [fullName, email, phone, message, send]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (!fullNameError & !emailError && !messageError) {
            SendEmail({ fullName, email, phone, company, message, setSend})
        }
    };

    return (
        <div className='c-container'>
            <Toast />
            <div className='main-box'>
                <a href='/'>
                    <BsArrowLeftCircle className='arrow-icon'/>
                </a>
                <h1>Contáctanos</h1>
                <div className='c-img'>
                    <img src={ContactImg} alt='ContactImg' />
                </div>
                <form className='c-form' onSubmit={submitHandler}>
                    <div className='input-label'>
                        <input
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        type='text'
                        placeholder='Ususario Doe' 
                        required 
                        />
                    </div>
                    {fullNameError && <InlineError error={fullNameError} />}
                    <div className='input-label'>
                        <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email' 
                        placeholder='example@gmail.com' 
                        required />
                    </div>
                    {emailError && <InlineError error={emailError} />}
                    <div className='input-label'>
                        <input 
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type='text'
                        placeholder='1234-5678' 
                        required />
                    </div>
                    <div className='input-label'>
                        <input 
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        type='text' 
                        placeholder='Nombre de Empresa'
                        />
                    </div>
                    <div className='input-label'>
                        <textarea 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        type='text' 
                        placeholder='En que podemos ayudarle?' 
                        rows={5} 
                        required />
                    </div>
                    {messageError && <InlineError error={messageError} />}
                    <p></p>
                    <button type='submit'>Enviar</button>
                    <div className='c-social-media'>
                        <a href='/' target='_blank'><FaFacebook /></a>
                        <a href='/' target='_blank'><FaInstagram /></a>
                        <a href='/' target='_blank'><FaWhatsapp /></a>
                    </div>
                </form>
            </div>
        </div>
    )
}