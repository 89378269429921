import React from "react";
import {FcEditImage} from 'react-icons/fc'
import {FcWorkflow} from 'react-icons/fc'
import {FcCollaboration} from 'react-icons/fc'
import Card from "./Card";
import '../../Style/Servicios/Services.css';


export const Services = () => {
    return (
        <div className="container-service" id="servicios">
            <h1 className="h1-service">Servicios</h1>
            <div className="cards">
                <Card
                    Icon={FcEditImage}
                    title={'Desarrollo Web'}
                    disc = {'Bienvenido a nuestro servicio de desarrollo web, donde transformamos ideas en experiencias digitales excepcionales. En WOSS, nos enorgullece ofrecer soluciones web personalizadas y vanguardistas que impulsan tu presencia en línea y potencian el crecimiento de tu negocio.'}
                />
                <Card
                    Icon={FcWorkflow}
                    title={'Desarrollo De Software'}
                    disc = {'Nuestro equipo de desarrolladores altamente calificados está dedicado a transformar tus ideas en soluciones de software robustas y escalables. Desde aplicaciones empresariales hasta plataformas personalizadas, nos enorgullece ofrecer un enfoque integral que abarca el diseño, el desarrollo, la implementación y el mantenimiento continuo.'}
                />
                <Card
                    Icon={FcCollaboration}
                    title={'E-Marketing'}
                    disc = {'Somos tu aliado estratégico en el emocionante mundo del E-marketing. En la era digital actual, la presencia en línea y la estrategia de marketing adecuada son fundamentales para el éxito empresarial. En [Tu Empresa], ofrecemos servicios de E-marketing diseñados para elevar tu marca, expandir tu alcance y aumentar tus conversiones.'}
                />
            </div>
        </div>
    )
}