import axios from 'axios';

export const SendEmail = async ({
    fullName,
    email,
    phone,
    company,
    message,
    setSend,
}) => {
    try {
        const datas = {fullName, email, phone, company, message};
        let res = await axios.post(`http://localhost:5000/send`, datas);
        if (res){
            setSend(res.data);
        }
    } catch (error) {
        alert(error.response.data.message);
    }
};