import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA34NjKNj0XwJnAkbfng0Ezx9hWcnZj7v8",
    authDomain: "newsletter-app-f2468.firebaseapp.com",
    projectId: "newsletter-app-f2468",
    storageBucket: "newsletter-app-f2468.appspot.com",
    messagingSenderId: "994258335432",
    appId: "1:994258335432:web:399dac052fc3ba207397fd"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export {app, db};