import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import react from '../../Assets/react-2.svg';
import node from '../../Assets/nodejs-icon.svg';
import javascript from '../../Assets/logo-javascript.svg';
import bootstrap from '../../Assets/bootstrap-5-1.svg';
import css from '../../Assets/css-3.svg';
import html from '../../Assets/html-1.svg';
import tailwind from '../../Assets/tailwind-css-2.svg';
import vite from '../../Assets/vitejs.svg';
import nextjs from '../../Assets/next-js.svg';
import '../Style/Nosotros.css'

export const Nosotros = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="nosotros" id="nosotros">
            <Container>
                <Row>
                    <Col>
                        <div className="nosotros-bx">
                            <h2>Tecnologías</h2>
                            <p>Usamos las mejores Tecnologías para darte
                                la mejor experiencia y el mejor producto.
                            </p>
                            <Carousel responsive={responsive} infinite={true} className="slider">
                                <div className="item">
                                    <img src={react} alt="Image1" />
                                    <h5>REACT JS</h5>
                                </div>
                                <div className="item">
                                    <img src={node} alt="Image2" />
                                    <h5>NODE JS</h5>
                                </div>
                                <div className="item">
                                    <img src={nextjs} alt="Image3" />
                                    <h5>NEXT JS</h5>
                                </div>
                                <div className="item">
                                    <img src={javascript} alt="Image3" />
                                    <h5>JAVASCRIPT</h5>
                                </div>
                                <div className="item">
                                    <img src={vite} alt="Image3" />
                                    <h5>VITE</h5>
                                </div>
                                <div className="item">
                                    <img src={tailwind} alt="Image3" />
                                    <h5>TAILWIND</h5>
                                </div>
                                <div className="item">
                                    <img src={bootstrap} alt="Image3" />
                                    <h5>BOOTSTRAP</h5>
                                </div>
                                <div className="item">
                                    <img src={css} alt="Image3" />
                                    <h5>CSS</h5>
                                </div>
                                <div className="item">
                                    <img src={html} alt="Image3" />
                                    <h5>HTML</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}