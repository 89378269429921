import Project1 from '../../../Assets/project1.png'
import Project2 from '../../../Assets/tpc.png';
import Project3 from '../../../Assets/Lers.png';
import Project4 from '../../../Assets/Jacalito.png';

export const projectsData = [
    {
        title: 'Royal Fast and Safe',
        description: 'Proyecto Desarrollado para la empresa Royal Fast and Safe Transportation, ubicada en Estados Unidos.',
        imgUrl: Project1,
        tags: 'React Js',
        date: 'October 10, 2023 Guatemala',
        details: 'https://bryanparedes.com/',
    },
    {
        title: 'TPC EXPRESS',
        description: 'Proyecto para empresa de mensajería local en guatemala, con funciones como cotizador y envio de datos por Correo.',
        imgUrl: Project2,
        tags: 'React Js',
        date: 'Diciembre 2, 2023 Guatemala',
        details: 'https://tpcxpress.com',
    },
    {
        title: 'Lers',
        description: 'Landing Page con información de empresa dedicada al aprendizaje de Ingles.',
        imgUrl: Project3,
        tags: 'React Js',
        date: 'Septiembre 8, 2020 Guatemala',
        details: 'https://www.lersguatemala.com',
    },
    {
        title: 'El jacalito',
        description: 'Landing Page Desarrollado para restaurante de comida Mexicana, ubicado en Estados Unidos.',
        imgUrl: Project4,
        tags: 'React Js',
        date: 'Julio 5, 2020 Guatemala',
        details: 'https://www.eljacalitomiami.com',
    },
];