import React from 'react'
import '../../Style/Projects.css'
import { projectsData } from './ProjectsData';
import { ProjectCard } from './ProjectsCard';
import { Nav } from 'react-bootstrap';

export const Projects = () => {
    return (
        <section className='project' id='proyectos'>
            <div className='wrapper'>
                <h2>Proyectos</h2>
                <p>Visualiza algunos de los proyectos que hemos desarrolado
                    para Empresas.
                </p>
                    <div id='projects-tabs' defaultActiveKey='first'>
                        <Nav variant="pills" defaultActiveKey="/home" className='nav-group'>
                            <Nav.Item>
                                <Nav.Link>Desarrollo Web</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className='project-container-card'>
                            <div id='project-card'>
                                {
                                    projectsData.map((project, index) => {
                                        return(
                                            <ProjectCard
                                                key={index}
                                                {...project}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    )
}
