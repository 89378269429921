import { Col } from "react-bootstrap"
import '../../Style/Projects.css'
import { FcLink } from 'react-icons/fc'

export const ProjectCard = ({title, description, imgUrl, tags, details, date}) => {
    return (
        <Col className="CardContainer">
            <div className="card-bx">
                <img src={imgUrl} alt="ProjectImg" />
                <div className="tags">
                    {tags}
                </div>
                <div className="details">
                    <h3>{title}</h3>
                    <h5>{date}</h5>
                    <p>{description}</p>
                    <a href={details} target="_blank" rel="noreferrer">Visite Website <FcLink className="icon"/></a>
                </div>
            </div>
        </Col>
    )
}