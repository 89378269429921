import React from 'react'
import '../../Style/Servicios/Card.css';

const Card = (props) => {
    const { Icon, disc, title } = props;
    return (
        <div className='container-card-1'>
            <span><Icon/></span>
            <h2>{title}</h2>
            <p>{disc}</p>
        </div>
    )
}

export default Card