    const validateEmail = ({ email, setEmailError }) => {
        const emailRegular =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && !email.match(emailRegular)
        ? setEmailError('Email no Valido')
        : setEmailError('');
    };

    // const validatePhone = ({ phone, setPhoneError }) => {
    //     var phoneRegular = 
    //     /^\(?([0-9]{3})\)?[-. ]?([0-6]{9})[-. ]?([0-9]{3})$/;
    // return phone && !phone.match(phoneRegular)
    //     ? setPhoneError('Numero de Telefono No Valido')
    //     : setPhoneError('');
    // };

    const validateFullName = ({ fullName, setFullNameError }) => {
        return fullName && fullName.length < 5
            ? setFullNameError('Nombre es demasiado corto')
            : fullName && fullName.length > 50
            ? setFullNameError('Intenta que sea breve y significativo')
            : setFullNameError('');
    };

    const validateMessage = ({ message, setMessageError }) => {
        return message && message.length < 5
        ? setMessageError('Mensaje demasiado Corto')
        : message && message.length > 200
        ? setMessageError('Intenta que sea breve y significativo')
        : setMessageError('');
    };

    export { validateEmail, validateFullName, validateMessage };