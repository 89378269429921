import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { FiPhoneCall } from 'react-icons/fi';
import { BiMailSend } from 'react-icons/bi';
import Logo from '../../Assets/Logo.png';
import '../Style/Footer.css';


export const Footer = () => {
    return (
        <footer className="">
            <div className="">
                <div className="container-footer">
                    <div className="img-footer">
                        <a className="" href="/">
                            <img src={Logo} alt='Logo' />
                        </a>
                        <p>at risus viverra adipiscing at in tellus integer
                        feugiat scelerisque varius morbi enim nunc faucibus</p>
                    </div>
                    <div className="pages-footer">
                        <h5 className=''>Paginas</h5>
                        <a href='#home' className=''>Home</a>
                        <a href='#nosotros' className=''>Nosotros</a>
                        <a href='#servicios' className=''>Servicios</a>
                        <a href='#proyectos' className=''>Proyectos</a>
                        
                    </div>
                    <div className="contact-footer">
                        <h5 className=''>Contacto</h5>
                        <a href='tel:'><FiPhoneCall className='info-icon-footer'/>+(502)3899-7113</a>
                        <a href='mailto:bryansparedes2@gmail.com'><BiMailSend className='info-icon-footer'/>sotfwarewoss@gmail.com</a>
                        <a href='/'><FaLocationDot className='info-icon-footer'/>Guatemala, Guatemala</a>
                    </div>
                </div>
                <hr />
                <div className='social-copy'>
                    <a href='/' className=''><FaFacebook /></a>                        
                    <a href='/' className=''><FaInstagram /></a>                        
                    <a href='/' className=''><FaWhatsapp /></a>                       
                </div>
                <div className='copy-info'>
                    <p>Copyright ©2023 Todos los derechos reservados</p>
                </div>
            </div>
        </footer>
    )
}
